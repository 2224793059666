<template>
  <b-row>
    <b-col cols="12">
      <divisoria-descricao
        class="mt-2"
        :titulo="'Informações do contador'"
        :descricao="'Informações do contador responsável pela empresa.'"
      />
      <b-row>
        <b-col cols="6">
          <validation-provider
            #default="{ errors }"
            rules="required|possuiId"
            name="Contador"
          >
            <contador-select
              v-model="valueIn"
              @input="$emit('input', valueIn)"
            />
            <small
              v-if="errors[0]"
              class="text-danger"
            >
              Contador é obrigatorio
            </small>
          </validation-provider>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="CRC"
            label-for="crcInput"
          >
            <b-input
              id="crcInput"
              disabled
              :value="valueIn ? valueIn.crc : ''"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label-for="cpfContadorInput"
            label="CPF do contador"
          >
            <masked-input
              id="cpfContadorInput"
              disabled
              :value="valueIn ? valueIn.cpf : ''"
              :mask="cpf"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

import { cpfDocumento } from '@pilar/plugins/p-validation/p-validation'
import {
  cpf,
} from '@pilar/mixins/mascaras'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'SecaoContador',
  components: {
    ValidationProvider,
    ContadorSelect: () => import('@/app/shared/components/contador-select/ContadorSelect.vue'),
    DivisoriaDescricao: () => import('@pilar/components/divisoria-descricao/DivisoriaDescricao.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cpfDocumento,
      cpf,
      valueIn: {},
    }
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.valueIn = value
      }
    },
  },
  created() {
    this.valueIn = this.value
  },
}
</script>
